import { template as template_26a93fb4f4624f558a7ec7740d331060 } from "@ember/template-compiler";
const FKText = template_26a93fb4f4624f558a7ec7740d331060(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

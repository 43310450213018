import { template as template_5594b8d18eb046e2a5d1f9d3e63b1335 } from "@ember/template-compiler";
const WelcomeHeader = template_5594b8d18eb046e2a5d1f9d3e63b1335(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

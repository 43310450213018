import { template as template_2330e95eccde4d468a580802f4b3d64a } from "@ember/template-compiler";
const FKControlMenuContainer = template_2330e95eccde4d468a580802f4b3d64a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

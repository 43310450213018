import { template as template_78de060f9999466b9337f407ee7e6962 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_78de060f9999466b9337f407ee7e6962(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
